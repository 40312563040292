<template>
  <div>
    <a-alert
      :message="typeName"
      :description="$t('scolarite.infoModuleVerySpec')"
      type="success"
      show-icon
      closable
    />
    <div class="row">
      <div class="col-md-4">
        <a-select
          size="large"
          :default-value="defaultLevel[schoolType]"
          @change="handleModuleChange"
          class="pb-2 pt-2"
          style="width: 100%"
        >
          <a-select-option
            v-for="(value, name, index) in levelSelectData[schoolType]"
            :key="index"
            :value="name"
          >
            {{ value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="col-md-4 row">
        <div class="col-md-3 pt-2 center">
          <label style="margin-right: 5px; margin-left: 15px">{{
            this.$t("emploi.trimestre")
          }}</label>
        </div>
        <div class="col-md-9">
          <a-select
            size="large"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%; height: 100%; margin-left: 15px"
            class="pb-2 p-2"
            @change="
              (val) => {
                this.selectedTrimester = val;
                this.handleModuleChange(this.levelSelected);
              }
            "
            default-value="1"
          >
            <a-select-option
              v-for="trimester in ['1', '2', '3']"
              :key="trimester"
              :value="trimester"
            >
              {{ trimester }}
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div class="col-md-2">
        <a-button
          type="primary"
          class="mx-auto my-2"
          @click="cloneModel = true"
          style="width: 100%"
        >
          {{ $t("action.clone") }}
        </a-button>
      </div>

      <div class="col-md-2">
        <a-button
          type="primary"
          icon="book"
          class="mx-auto my-2"
          @click="showAddModal"
          style="width: 100%"
        >
          {{ $t("action.ajouter") }}
        </a-button>
      </div>
    </div>

    <a-modal
      v-model="addSubsubjectVisible"
      @cancel="closeAddSubsubject"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="subsubjectForm" @submit="addSubsubject">
        <a-form-item :label="$t('scolarite.nom')">
          <a-input
            :placeholder="$t('scolarite.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button
            key="back"
            @click="closeAddSubsubject"
            :disabled="loadingClasse"
          >
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>

    <a-modal
      v-model="subsubjectVisible"
      :title="$t('scolarite.addNewSubsubject')"
      :footer="false"
      width="40%"
    >
      <a-button
        type="dashed"
        size="large"
        icon="book"
        @click="
          () => {
            addSubsubjectVisible = true;
          }
        "
        style="margin-bottom: 20px"
      >
        {{ $t("action.ajouter") }}
      </a-button>
      <a-table
        :loading="tableLoadingSubsubjects"
        :rowKey="'_id'"
        :columns="subsubjectsColumns"
        :data-source="listSubsubjects"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="name" slot-scope="text, record">
          <div key="name">
            <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="
                (e) =>
                  handleChangeSubsubject(e.target.value, record._id, 'name')
              "
            />
            <template v-else> {{ text }} </template>
          </div>
        </template>
        <span slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span v-if="record.editable">
              <a-button
                size="small"
                type="primary"
                @click="() => saveSubsubject(record._id)"
              >
                {{ $t("action.enregistrer") }}
              </a-button>
              <a-button size="small" type="danger">
                <a-popconfirm
                  :title="$t('all.sureToCancelAlert')"
                  @confirm="() => cancelSubsubject(record._id)"
                >
                  <a> {{ $t("action.annuler") }} </a>
                </a-popconfirm>
              </a-button>
            </span>
            <span v-else>
              <a-button
                type="primary"
                :disabled="subsubjectEditingKey !== ''"
                @click="() => editSubsubject(record._id)"
              >
                <a-icon type="edit" />{{ $t("action.modifier") }}
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
      <div class="form-actions mt-0">
        <a-button
          key="back"
          @click="
            () => {
              subsubjectVisible = false;
            }
          "
        >
          {{ $t("action.annuler") }}
        </a-button>
      </div>
    </a-modal>

    <div class="card">
      <!-- add module form -->
      <a-modal
        v-model="visibleAddModal"
        @cancel="handleCancel('add')"
        :title="$t('scolarite.ajouterModule')"
        :footer="false"
        width="60%"
      >
        <a-form :form="addForm" @submit="addModule">
          <a-row style="width: 100%" type="flex" justify="space-between">
            <a-col :span="9">
              <a-form-item :label="$t('scolarite.nom')">
                <a-input
                  :placeholder="$t('scolarite.nom')"
                  v-decorator="[
                    'name',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item :label="$t('scolarite.coefficient')">
                <a-input-number
                  style="width: 100%; margin-left: 10px"
                  :placeholder="$t('scolarite.coefficient')"
                  v-decorator="[
                    'coef',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.coefficient'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4" class="justify-content-center">
              <a-form-item :label="$t('bulletin.useSubModule')">
                <a-switch
                  v-decorator="[
                    'useSubModule',
                    {
                      initialValue: useSubModule,
                      getValueFromEvent: defaultgetValueFromEvent,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <div
              class="card"
              v-if="selectedSubjects.length == 0"
              style="width: 100%; padding: 10px"
            >
              <a-empty :image="simpleImage" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in selectedSubjects"
                :key="index"
                class="card"
                style="width: 100%; padding: 10px"
              >
                <a-label>{{ $t("emploi.matiere") }}: </a-label>
                <a-row type="flex" justify="space-between">
                  <a-col :span="12">
                    <a-select
                      show-search
                      label-in-value
                      :placeholder="$t('emploi.choisirMatiere')"
                      :default-value="item.subject"
                      v-model="item.subject"
                      :filter-option="filterSubjects"
                      @change="
                        (val) => {
                          item.subject = val;
                        }
                      "
                    >
                      <a-select-option
                        v-for="subject in unusedSubjects"
                        :key="subject._id"
                        :value="subject._id"
                      >
                        {{ subject.name }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="useSubModule ? 3 : 8">
                    <a-input-number
                      style="width: 100%"
                      class="ml-2"
                      :value="item.coef"
                      v-model="item.coef"
                      :placeholder="$t('scolarite.coefficient')"
                  /></a-col>
                  <a-col :span="5" v-if="useSubModule">
                    <a-select
                      show-search
                      :placeholder="$t('emploi.chooseSubmodule')"
                      :default-value="item.sousModule"
                      v-model="item.sousModule"
                      :filter-option="filterSubjects"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="submodule in submodules"
                        :key="submodule._id"
                        :value="submodule._id"
                      >
                        {{ submodule.name }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col :span="2">
                    <a-button
                      type="danger"
                      class="ml-4"
                      shape="circle"
                      icon="delete"
                      @click="
                        () => {
                          selectedSubjects.splice(index, 1);
                        }
                      "
                    />
                  </a-col>
                </a-row>
                <a-label>{{ $t("scolarite.subsubjects") }}:</a-label>
                <div
                  class="card"
                  v-if="item.subsubjects && item.subsubjects.length > 0"
                  style="padding: 10px; margin-bottom: 10px"
                >
                  <a-row>
                    <a-col :span="14">
                      <a-label>{{ $t("scolarite.subsubject") }}: </a-label>
                    </a-col>
                    <a-col :span="4">
                      <a-label>{{ $t("scolarite.mark") }}: </a-label>
                    </a-col>
                    <a-col :span="4">
                      <a-label>{{ $t("scolarite.coefficient") }}: </a-label>
                    </a-col>
                  </a-row>
                  <a-row v-for="(item2, i) in item.subsubjects" :key="i">
                    <a-col :span="14">
                      <a-select
                        show-search
                        label-in-value
                        :placeholder="$t('scolarite.choisirSubsubject')"
                        :default-value="item2.subsubject"
                        v-model="item2.subsubject"
                        :filter-option="filterSubjects"
                      >
                        <a-select-option
                          v-for="subsubject in unusedSubsubjects(item)"
                          :key="subsubject._id"
                          :value="subsubject._id"
                        >
                          {{ subsubject.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="4" class="pl-2">
                      <a-input-number
                        class="w-100"
                        :value="item2.mark"
                        v-model="item2.mark"
                        :min="1"
                        :placeholder="$t('scolarite.mark')"
                      />
                    </a-col>
                    <a-col :span="4" class="pl-2">
                      <a-input-number
                        class="w-100"
                        :value="item2.coef"
                        v-model="item2.coef"
                        :default-value="1"
                        :min="0"
                        :placeholder="$t('scolarite.coefficient')"
                      />
                    </a-col>
                    <a-col :span="2" class="pl-2">
                      <a-button
                        type="dashed"
                        shape="circle"
                        icon="delete"
                        @click="
                          () => {
                            selectedSubjects[index].subsubjects.splice(i, 1);
                          }
                        "
                      />
                    </a-col>
                  </a-row>
                </div>
                <a-row style="margin-top: 0px">
                  <a-col :span="4"></a-col>
                  <a-col :span="8">
                    <a-button
                      icon="plus"
                      type="primary"
                      class="mr-3 m-auto"
                      style="width: 100%"
                      @click="
                        () => {
                          selectedSubjects[index].subsubjects.push({
                            subsubject: undefined,
                            mark: undefined,
                            coef: 1,
                          });
                        }
                      "
                    >
                      {{ $t("scolarite.ajouterSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="8">
                    <a-button
                      icon="copy"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          subsubjectVisible = true;
                        }
                      "
                    >
                      {{ $t("scolarite.addNewSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="4"></a-col>
                </a-row>
              </div>
            </div>
            <div class="m-0 m-auto">
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="8">
                  <a-button
                    icon="plus"
                    type="primary"
                    block
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                          subsubjects: [],
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.ajouter") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('add')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>
      <!-- edit module modal-->
      <a-modal
        v-model="visibleModal"
        @cancel="handleCancel"
        :title="
          $t('scolarite.modifierListeMatieres', { name: selectedModule.name })
        "
        :footer="false"
        width="60%"
        v-if="selectedModule"
      >
        <a-form :form="form" @submit="editModule">
          <a-row style="width: 100%" type="flex" justify="space-between">
            <a-col :span="9">
              <a-form-item :label="$t('scolarite.nom')">
                <a-input
                  :placeholder="$t('scolarite.nom')"
                  v-decorator="[
                    'name',
                    {
                      initialValue: selectedModule.name,
                      rules: [
                        {
                          required: true,
                          message: $t('requis.nom'),
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <a-form-item :label="$t('scolarite.coefficient')">
                <a-input-number
                  style="width: 100%; margin-left: 10px"
                  :placeholder="$t('scolarite.coefficient')"
                  :value="selectedModule.coef"
                  v-decorator="[
                    'coef',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.coefficient'),
                        },
                      ],
                      initialValue: selectedModule.coef,
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4" class="justify-content-center">
              <a-form-item :label="$t('bulletin.useSubModule')">
                <a-switch
                  v-model="usedSubModule"
                  v-decorator="[
                    'usedSubModule',
                    {
                      getValueFromEvent: defaultgetValueFromEventADD,
                      rules: [
                        {
                          required: false,
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item :label="$t('scolarite.listeMatieres')">
            <div
              class="card"
              v-if="selectedSubjects.length == 0"
              style="width: 100%; padding: 10px"
            >
              <a-empty :image="simpleImage" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in selectedSubjects"
                :key="index"
                class="card"
                style="width: 100%; padding: 10px"
              >
                <a-label>{{ $t("emploi.matiere") }}: </a-label>
                <a-row type="flex" justify="space-between">
                  <a-col :span="12">
                    <a-select
                      label-in-value
                      :placeholder="$t('emploi.choisirMatiere')"
                      :default-value="item.subject"
                      v-model="item.subject"
                      @change="
                        (val) => {
                          item.subject = val;
                        }
                      "
                    >
                      <a-select-option
                        v-for="subject in unusedSubjects"
                        :key="subject._id"
                        :value="subject._id"
                      >
                        {{ subject.name }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="usedSubModule ? 3 : 8">
                    <a-input-number
                      style="width: 100%"
                      :value="item.coef"
                      v-model="item.coef"
                      :placeholder="$t('scolarite.coefficient')"
                  /></a-col>
                  <a-col :span="5" v-if="usedSubModule">
                    <a-select
                      :default-value="item.sousModule"
                      v-model="item.sousModule"
                      style="width: 100%"
                    >
                      <a-select-option
                        v-for="submodule in submodules"
                        :key="submodule._id"
                        :value="submodule._id"
                      >
                        {{ submodule.name }}</a-select-option
                      >
                    </a-select>
                  </a-col>
                  <a-col :span="2">
                    <a-button
                      type="danger"
                      class="ml-3"
                      shape="circle"
                      icon="delete"
                      @click="
                        () => {
                          selectedSubjects.splice(index, 1);
                        }
                      "
                    />
                  </a-col>
                </a-row>
                <a-label>{{ $t("scolarite.subsubjects") }}:</a-label>
                <div
                  class="card"
                  v-if="item.subsubjects && item.subsubjects.length > 0"
                  style="padding: 10px; margin-bottom: 10px"
                >
                  <a-row>
                    <a-col :span="14">
                      <a-label>{{ $t("scolarite.subsubject") }}: </a-label>
                    </a-col>
                    <a-col :span="4">
                      <a-label>{{ $t("scolarite.mark") }}: </a-label>
                    </a-col>
                    <a-col :span="4">
                      <a-label>{{ $t("scolarite.coefficient") }}: </a-label>
                    </a-col>
                  </a-row>
                  <a-row
                    v-for="(item2, i) in item.subsubjects"
                    :key="i"
                    class="g-2"
                  >
                    <a-col :span="14">
                      <a-select
                        label-in-value
                        :placeholder="$t('scolarite.choisirSubsubject')"
                        :default-value="item2.subsubject"
                        v-model="item2.subsubject"
                      >
                        <a-select-option
                          v-for="subsubject in unusedSubsubjects(item)"
                          :key="subsubject._id"
                          :value="subsubject._id"
                        >
                          {{ subsubject.name }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="4" class="pl-2">
                      <a-input-number
                        class="w-100"
                        :value="item2.mark"
                        v-model="item2.mark"
                        :min="1"
                        :placeholder="$t('scolarite.mark')"
                      />
                    </a-col>
                    <a-col :span="4" class="pl-2">
                      <a-input-number
                        class="w-100"
                        :value="item2.coef"
                        v-model="item2.coef"
                        :default-value="1"
                        :min="0"
                        :placeholder="$t('scolarite.coefficient')"
                      />
                    </a-col>
                    <a-col :span="2" class="pl-2">
                      <a-button
                        type="dashed"
                        shape="circle"
                        icon="delete"
                        @click="
                          () => {
                            selectedSubjects[index].subsubjects.splice(i, 1);
                          }
                        "
                      />
                    </a-col>
                  </a-row>
                  <a-row
                    ><a-col :span="24">
                      <a-alert
                        :message="
                          getFormule(
                            item.subject,
                            selectedSubjects[index].subsubjects
                          )
                        "
                        type="info"
                      /> </a-col
                  ></a-row>
                </div>
                <a-row style="margin-top: 0px">
                  <a-col :span="4"></a-col>
                  <a-col :span="8">
                    <a-button
                      icon="plus"
                      type="primary"
                      class="mr-3 m-auto"
                      style="width: 100%"
                      @click="
                        () => {
                          selectedSubjects[index].subsubjects.push({
                            subsubject: undefined,
                            mark: undefined,
                          });
                        }
                      "
                    >
                      {{ $t("scolarite.ajouterSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="8">
                    <a-button
                      icon="copy"
                      type="dashed"
                      class="mr-3 m-auto"
                      style="width: 250px"
                      @click="
                        () => {
                          subsubjectVisible = true;
                        }
                      "
                    >
                      {{ $t("scolarite.addNewSubsubject") }}
                    </a-button>
                  </a-col>
                  <a-col :span="4"></a-col>
                </a-row>
              </div>
            </div>
            <div class="m-0 m-auto">
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="8">
                  <a-button
                    icon="plus"
                    type="primary"
                    block
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        selectedSubjects.push({
                          subject: undefined,
                          coef: undefined,
                          subsubjects: [],
                        });
                      }
                    "
                  >
                    {{ $t("scolarite.ajouterMatiere") }}
                  </a-button>
                </a-col>
                <a-col :span="8"></a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="form-actions mt-0">
            <a-button
              type="primary"
              htmlType="submit"
              :loading="loadingClasse"
              :disabled="loadingClasse"
              class="mr-3"
            >
              {{ $t("action.modifier") }}
            </a-button>
            <a-button
              key="back"
              @click="handleCancel('edit')"
              :disabled="loadingClasse"
            >
              {{ $t("action.annuler") }}
            </a-button>
          </div>
        </a-form>
      </a-modal>

      <a-table
        :loading="tableLeading"
        :rowKey="'_id'"
        :columns="columns"
        :data-source="data"
        :pagination="true"
        :scroll="{ x: 'max-content' }"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  affSubjects(record);
                },
              },
            };
          }
        "
        :components="components"
      >
        <template slot="dragHandle">
          <a-icon
            type="menu"
            class="drag-handle"
            style="cursor: move; font-size: 17px"
          />
        </template>
        <template slot="subjects" slot-scope="text, record">
          <div
            v-for="subject in record.subjects"
            :key="subject._id"
            class="arabicStyle"
          >
            -
            <a-tag color="green" class="arabicStyle"
              >{{ subject.name }} ({{
                record.subjectsInfo ? record.subjectsInfo[subject._id].coef : 1
              }})</a-tag
            >
          </div>
        </template>
        <div slot="filterDropdown" slot-scope="{ column }" style="padding: 8px">
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <span slot="operationSupprimer" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button :disabled="editingKey !== ''" type="danger" @click.stop>
                <a-popconfirm
                  :title="$t('requis.supp')"
                  @confirm="() => supp(record)"
                >
                  <a>{{ $t("action.supprimer") }}</a>
                </a-popconfirm>
              </a-button>
            </span>
          </div>
        </span>
      </a-table>
    </div>

    <div class="card">
      <!-- add module form -->
      <a-modal
        v-model="cloneModel"
        @cancel="cloneModel = false"
        :title="$t('scolarite.duppModules')"
        :footer="false"
        width="60%"
      >
        <div>
          <div class="">
            <label>{{ this.$t("actualite.trimester_one") }}</label>
          </div>
          <div class="">
            <a-select
              size="large"
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 100%; height: 100%"
              class="pb-2 p-2"
              @change="
                (val) => {
                  this.trimester_one = val;
                }
              "
              default-value="1"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
              >
                {{ trimester }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div>
          <div class="">
            <label>{{ this.$t("actualite.trimester_two") }}</label>
          </div>
          <div class="">
            <a-select
              size="large"
              :placeholder="$t('paiement.selectionnerMois')"
              style="width: 100%; height: 100%"
              class="pb-2 p-2"
              @change="
                (val) => {
                  this.trimester_two = val;
                }
              "
              default-value="2"
            >
              <a-select-option
                v-for="trimester in ['1', '2', '3']"
                :key="trimester"
                :value="trimester"
              >
                {{ trimester }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            type="primary"
            :loading="loadingClasse"
            class="mr-3"
            @click="cloneModelByTrimester()"
          >
            {{ $t("action.clone") }}
          </a-button>
          <a-button
            key="back"
            @click="cloneModel = false"
            :disabled="loadingClasse"
          >
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-modal>
      <a-modal
        v-model="moduleConflictexist"
        title="La note maximale de certain sous matières est contradictoire avec ces modules"
        @ok="cancelConflictNotif"
        centered
        destroy-on-close
      >
        <div class="card p-2">
          <a-tree :show-line="true" :defaultExpandAll="true" :show-icon="true">
            <a-tree-node
              :checkable="false"
              v-for="(notifItem, notifItemIndex) in moduleConflict"
              :key="'0-' + notifItemIndex"
            >
              <a-icon slot="icon" type="book" />
              <span slot="title">
                {{ notifItem.name }}
                <a-tag color="blue">{{ notifItem.type }}</a-tag>
              </span>
              <a-tree-node
                :checkable="false"
                v-for="(subjectItem, subjectItemIndex) in notifItem.subjects"
                :key="'0-' + notifItemIndex + '-' + subjectItemIndex"
              >
                <a-icon slot="icon" type="form" />
                <span slot="title">
                  {{ subjectItem.subject }}
                </span>
                <a-tree-node
                  :checkable="false"
                  v-for="(
                    conflictItem, conflictItemIndex
                  ) in subjectItem.conflict"
                  :key="
                    '0-' +
                    notifItemIndex +
                    '-' +
                    subjectItemIndex +
                    '-' +
                    conflictItemIndex
                  "
                >
                  <a-icon slot="icon" type="diff" />
                  <span slot="title">{{ conflictItem.subsubject }}</span>
                  <a-tree-node
                    :checkable="false"
                    :isLeaf="true"
                    :key="
                      '0-' +
                      notifItemIndex +
                      '-' +
                      subjectItemIndex +
                      '-' +
                      conflictItemIndex +
                      '-0'
                    "
                  >
                    <a-icon slot="icon" type="close" />
                    <span slot="title"
                      >Note maximal de ce module:
                      {{ conflictItem.innerMark }}</span
                    >
                  </a-tree-node>
                  <a-tree-node
                    :checkable="false"
                    :isLeaf="true"
                    :key="
                      '0-' +
                      notifItemIndex +
                      '-' +
                      subjectItemIndex +
                      '-' +
                      conflictItemIndex +
                      '-1'
                    "
                  >
                    <a-icon slot="icon" type="check" />
                    <span slot="title"
                      >Note maximal de l'autre module:
                      {{ conflictItem.outerMark }}</span
                    >
                  </a-tree-node>
                </a-tree-node>
              </a-tree-node>
            </a-tree-node>
          </a-tree>
        </div>
      </a-modal>
      <subject-student
        :data="markConflict"
        :visible="markConflictModal"
        @cancel="cancelMarkConflictModal"
        @ok="solvedMarkConflict"
        :key="markConflictKey"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import SubjectStudent from "./components/SubjectStudent/index.vue";
import { mapState } from "vuex";
import { Empty } from "ant-design-vue";
import DraggableWrapper from "./components/DraggableWrapper/index.vue";

var modulesData = [];
export default {
  name: "GeneralModule",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      data: this,
    };
  },
  components: {
    SubjectStudent,
    DraggableWrapper,
  },
  computed: {
    ...mapState(["settings", "user"]),
    unusedSubjects() {
      return this.listSubjects.filter(
        (s) =>
          !this.selectedSubjects.find(
            (ss) => ss.subject && ss.subject.key == s._id
          )
      );
    },
    typeName() {
      switch (this.type) {
        case "MP":
          return this.$t("scolarite.listeModules");
        case "MT":
          return this.$t("scolarite.listeModulesSpec");
        case "MS":
          return this.$t("scolarite.listeModulesVerySpec");
      }
    },
  },
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "SubjectForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - " + this.typeName,
    });

    this.schoolType = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    ).type;
    this.levelSelected = Number(this.defaultLevel[this.schoolType]);

    this.getModules(this.defaultLevel[this.schoolType], this.selectedTrimester);

    await apiClient
      .post("/subject/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.listSubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
        this.tableLoadingSubsubjects = false;
      })
      .catch(() => this.$message.error(this.$t("error.aucMatiere")));

    await apiClient
      .post("/subsubject/filter", {
        query: {
          status: "active",
        },
      })
      .then((res) => {
        this.listSubsubjects = res.data.map((elem) => ({
          ...elem,
          key: elem._id,
        }));
        this.listSubsubjectsCache = [...this.listSubsubjects];
      })
      .catch(() => this.$message.error(this.$t("error.aucSubsubject")));
    apiClient
      .post("/bulletin/v2/sub-module/list", { status: "active" })
      .then(({ data }) => (this.submodules = data))
      .catch(() => this.$message.error(this.$t("error.noSubMoudoleIsfound")));
  },
  data() {
    return {
      markConflictKey: 1,
      usedSubModule: false,
      moduleConflict: {},
      markConflict: [],
      moduleConflictexist: false,
      markConflictExist: false,
      markConflictModal: false,
      useSubModule: false,
      cloneModel: false,
      trimester_one: "1",
      trimester_two: "2",
      schoolType: null,
      selectedTrimester: "1",
      columns: [
        {
          title: "",
          key: "dragHandle",
          width: 50,
          scopedSlots: { customRender: "dragHandle" },
        },
        {
          title: this.$t("scolarite.nomModule"),
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            record.name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("scolarite.coefficient"),
          dataIndex: "coef",
          sorter: true,
          key: "coef",
        },
        {
          title: this.$t("scolarite.listeMatieres"),
          dataIndex: "subjects",
          key: "subjects",
          scopedSlots: {
            customRender: "subjects",
          },
        },
        {
          width: 100,
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
        },
      ],
      subsubjectsColumns: [
        {
          title: this.$t("scolarite.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      tableLoadingSubsubjects: true,
      visibleModal: false,
      visibleAddModal: false,
      form: this.$form.createForm(this),
      addForm: this.$form.createForm(this),
      subsubjectForm: this.$form.createForm(this),
      data: null,
      subsubjectVisible: false,
      addSubsubjectVisible: false,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
      subsubjectEditingKey: "",
      selectedModule: null,
      listSubjects: [],
      listSubsubjects: [],
      listSubsubjectsCache: [],
      selectedSubjects: [],
      targetKeys: [],
      levelSelected: 1,
      targetAddKeys: [],
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      defaultLevel: {
        ecole: "1",
        college: "7",
        lycee: "10",
        collegeEtLycee: "7",
        primaireEtCollege: "1",
      },
      levelSelectData: {
        jardinEnfant: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
        },
        ecole: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
        },
        college: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
        lycee: {
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        collegeEtLycee: {
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
          10: this.$t("niveau.niveau10"),
          11: this.$t("niveau.niveau11"),
          12: this.$t("niveau.niveau12"),
          13: this.$t("niveau.niveau13"),
        },
        primaireEtCollege: {
          "-2": this.$t("niveau.niveauPre"),
          "-1": this.$t("niveau.niveau0"),
          1: this.$t("niveau.niveau1"),
          2: this.$t("niveau.niveau2"),
          3: this.$t("niveau.niveau3"),
          4: this.$t("niveau.niveau4"),
          5: this.$t("niveau.niveau5"),
          6: this.$t("niveau.niveau6"),
          7: this.$t("niveau.niveau7"),
          8: this.$t("niveau.niveau8"),
          9: this.$t("niveau.niveau9"),
        },
      },
      components: {
        body: {
          wrapper: DraggableWrapper,
        },
      },
    };
  },

  methods: {
    raiseConflictModal(data) {
      this.markConflict = data.map((mc) => {
        const subject = this.listSubjects.find((s) => s._id == mc.subject);
        if (subject) mc.subject = subject;
        if (mc.subsubject) {
          const subsubject = this.listSubsubjects.find(
            (s) => s._id == mc.subsubject
          );
          if (subsubject) mc.subsubject = subsubject;
        }
        return mc;
      });
      this.markConflictExist = true;
      this.markConflictModal = true;
    },
    cancelConflictNotif(e) {
      this.moduleConflictexist = false;
    },
    cancelMarkConflictModal() {
      this.markConflictModal = false;
    },
    solvedMarkConflict() {
      this.markConflictModal = false;
      this.markConflictExist = false;
      this.editModule();
    },
    defaultgetValueFromEvent(val) {
      this.useSubModule = val;
    },
    defaultgetValueFromEventADD(val) {
      this.usedSubModule = val;
    },
    async cloneModelByTrimester() {
      this.loadingClasse = true;
      await apiClient
        .put(
          "/bulletin/v2/module/clone/" +
            this.type +
            "/" +
            this.trimester_one +
            "/" +
            this.trimester_two +
            "/" +
            this.levelSelected +
            ""
        )
        .then(({ data }) => {
          this.data = data;
          this.cacheData = data;
          this.cloneModel = false;
        })
        .catch((err) => {
          this.$message.error(this.$t("error.erreur"));
          console.log(err);
        })
        .finally(() => {
          this.loadingClasse = false;
        });
    },
    filterSubjects(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    simplifyFraction(numerator, denominator) {
      try {
        if (denominator === 0) {
          return "--";
        }
        const findGCD = (a, b) => (b === 0 ? a : findGCD(b, a % b));
        const gcd = findGCD(Math.abs(numerator), Math.abs(denominator));
        const sign = numerator * denominator < 0 ? -1 : 1;
        return (
          ((sign * Math.abs(numerator)) / gcd).toFixed(2) +
          "/" +
          (Math.abs(denominator) / gcd).toFixed(2)
        );
      } catch (error) {
        return "--";
      }
    },
    getFormule(subject, subsubjects) {
      let sumCoef = 0;
      let subConcat = subject.label + " = ( ";
      let subArray = [];
      subsubjects.forEach((sub) => {
        sumCoef += sub.coef ?? 1;
        if (sub.subsubject) {
          let subSubCoef =
            sub.subsubject.label + " x " + sub.coef + "/" + sub.mark;
          subArray.push(subSubCoef);
        }
      });
      subConcat += subArray.join(" + ");
      subConcat += " )";
      return subConcat + " x 20/" + sumCoef;
    },
    unusedSubsubjects(subject) {
      return this.listSubsubjects.filter(
        (s) =>
          !subject.subsubjects.find(
            (ss) => ss.subsubject && ss.subsubject.key == s._id
          )
      );
    },
    async getModules(level, trimester) {
      await apiClient
        .post("/bulletin/v2/module/filter", {
          query: {
            status: "active",
            type: this.type,
            level: level,
            trimester: trimester ? trimester : 1,
          },
        })
        .then((res) => {
          modulesData = [...res.data];
          if (modulesData.length == 0)
            this.$message.warning(this.$t("error.aucModule"));
          const hasOrder = modulesData.some((elem) => elem.order !== undefined);

          if (!hasOrder) {
            modulesData = modulesData.map((elem, index) => ({
              ...elem,
              key: elem._id,
              order: index,
            }));
          } else {
            modulesData = modulesData.map((elem) => ({
              ...elem,
              key: elem._id,
              order: elem.order,
            }));
          }
          // Sort by order field
          modulesData.sort((a, b) => a.order - b.order);
          this.data = modulesData;
          this.cacheData = modulesData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => {
          this.$message.error(this.$t("error.erreur"));
          this.tableLeading = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async editModule(e) {
      e.preventDefault();

      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }

      for (const subject of this.selectedSubjects) {
        for (const sub of subject.subsubjects) {
          if (!sub.mark || !sub.subsubject.label || !sub.coef)
            return this.$message.error(
              this.$t("error.subsubjectNonAffecte", {
                subject: subject.subject.label,
              })
            );
        }
        /*if (
          subject.subsubjects &&
          subject.subsubjects.length != 0 &&
          marks != 20
        ) {
          this.$message.error(
            this.$t("scolarite.totalMarksMustBe20", {
              subject: subject.subject.label,
            })
          );
          return;
        }*/
      }

      this.loadingClasse = true;

      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.error("Received values of form: ", values);
        }
        if (this.usedSubModule) {
          for (const s of this.selectedSubjects) {
            if (!s.sousModule) {
              this.$message.error(
                this.$t("error.allSubModuleNeedToBeSelected")
              );
              this.loadingClasse = false;
              return;
            }
          }
        } else {
          this.selectedSubjects = this.selectedSubjects.map((s) => {
            if (s.sousModule) s.sousModule = null;
            return s;
          });
        }
        values.subjects = this.selectedSubjects.map((s) => s.subject.key);
        values.subsubjects = [];
        values.subjectsInfo = {};
        this.selectedSubjects.map((s) => {
          values.subjectsInfo[s.subject.key] = {
            coef: s.coef,
            sousModule: s.sousModule,
            subsubjectsMark: {},
          };
          s.subsubjects.map((sub) => {
            let subMarkData = {
              max: Number(sub.mark),
              coef: sub.coef ? Number(sub.coef) : 1,
            };
            values.subjectsInfo[s.subject.key].subsubjectsMark[
              sub.subsubject.key
            ] = subMarkData;
            values.subsubjects.push(sub.subsubject.key);
          });
        });
        delete values.usedSubModule;
        values.type = this.type;
        try {
          this.markConflictKey++;
          const crossCheckRes = await apiClient.post(
            "/bulletin/v2/module/filter/cross-check",
            {
              subjects: values.subjects,
              subsubjects: values.subsubjects,
              subjectsInfo: values.subjectsInfo,
              type: values.type,
              _id: this.selectedModule._id,
              level: this.levelSelected,
              trimester: this.selectedTrimester,
            }
          );
          const crossCheck = crossCheckRes.data;
          if (crossCheck.marks.length > 0)
            this.raiseConflictModal(crossCheck.marks);
          if (crossCheck.modules.length > 0) {
            let notif = [];
            for (const conflictModule of crossCheck.modules) {
              let type;
              switch (conflictModule.type) {
                case "MP":
                  type = this.$t("smartSchedule.module");
                case "MT":
                  type = this.$t("smartSchedule.moduleSpecific");
                case "MS":
                  type = this.$t("scolarite.modulesVerySpec");
              }
              notif.push({
                name: conflictModule.name,
                type: type,
                subjects: Object.entries(conflictModule.conflicts).map(
                  (conflict) => {
                    return {
                      subject: this.listSubjects.find(
                        (s) => s._id == conflict[0]
                      )["name"],
                      conflict: conflict[1].map((subsubjectConflict) => ({
                        ...subsubjectConflict,
                        subsubject: this.listSubsubjects.find(
                          (s) => s._id == subsubjectConflict.subsubject
                        )["name"],
                      })),
                    };
                  }
                ),
              });
            } //'La note maximale de certain sous matières est contraductoir avec ce module.'
            moduleConflict = notif;
            this.moduleConflictexist = true;
          }
        } catch (e) {
          this.$message.error(this.$t("error.impoModifiMatieres"));
          console.info(e.stack);
        }
        if (
          this.moduleConflictexist == true ||
          this.markConflictExist == true
        ) {
          this.loadingClasse = false;
          return;
        }
        apiClient
          .patch("/bulletin/v2/module/" + this.selectedModule._id, {
            data: values,
          })
          .then((res) => {
            this.selectedModule.subjects = this.listSubjects
              .filter((item) => values.subjects.includes(item._id))
              .sort(
                (a, b) =>
                  values.subjects.indexOf(a._id) -
                  values.subjects.indexOf(b._id)
              );
            this.selectedModule.coef = values.coef;
            this.selectedModule.name = values.name;
            this.selectedModule.subjectsInfo = values.subjectsInfo;

            var foundIndex = this.data.findIndex(
              (x) => x._id == this.selectedModule._id
            );
            this.data[foundIndex] = this.selectedModule;
            this.$message.success(
              this.$t("success.modificationsMatieresEffectue")
            );

            this.handleCancel();
          })
          .catch((e) =>
            this.$message.error(this.$t("error.impoModifiMatieres"))
          )
          .finally(() => {
            this.loadingClasse = false;
            this.moduleConflictexist = false;
            this.markConflictModal = false;
            this.markConflictExist = false;
          });
      });
    },
    checkLanguageModule() {
      let containsWord = false;
      let wordsToCheck = [
        "الاجنبية",
        "الأجنبية",
        "أجنبية",
        "اجنبية",
        "الاجنبيّة",
        "الأجنبيّة",
        "أجنبيّة",
        "اجنبيّة",
      ];
      for (const word of wordsToCheck) {
        if (this.selectedModule.name.includes(word)) {
          containsWord = true;
          break;
        }
      }
      return (
        (this.levelSelected == 5 || this.levelSelected == 6) && containsWord
      );
    },

    async addModule(e) {
      e.preventDefault();
      const thereEmpty = this.selectedSubjects.filter(
        (s) => !s.subject || !s.coef
      );

      if (
        !this.selectedSubjects ||
        this.selectedSubjects.length == 0 ||
        thereEmpty.length > 0
      ) {
        this.$message.warning(this.$t("error.matiereNonAffecte"));
        return;
      }
      for (const subject of this.selectedSubjects) {
        for (const sub of subject.subsubjects) {
          if (!sub.mark || !sub.subsubject.label || !sub.coef)
            return this.$message.error(
              this.$t("error.subsubjectNonAffecte", {
                subject: subject.subject.label,
              })
            );
        }
        /*   if (
          subject.subsubjects &&
          subject.subsubjects.length != 0 &&
          marks != 20
        ) {
          this.$message.error(
            this.$t("scolarite.totalMarksMustBe20", {
              subject: subject.subject.label,
            })
          );
          return;
        }*/
      }

      this.addForm.validateFields(async (err, values) => {
        if (err) console.error(err);
        if (!err) {
          if (this.useSubModule) {
            for (const s of this.selectedSubjects) {
              if (!s.sousModule) {
                this.$message.error(
                  this.$t("error.allSubModuleNeedToBeSelected")
                );
                this.loadingClasse = false;
                return;
              }
            }
          }
          this.loadingClasse = true;
          values.type = this.type;
          values.subjects = this.selectedSubjects.map((s) => s.subject.key);
          values.subsubjects = [];
          values.subjectsInfo = {};

          this.selectedSubjects.map((s) => {
            values.subjectsInfo[s.subject.key] = {
              coef: s.coef,
              subsubjectsMark: {},
              sousModule: s.sousModule,
            };
            s.subsubjects.map((sub) => {
              let subMarkData = {
                max: Number(sub.mark),
                coef: sub.coef ? Number(sub.coef) : 1,
              };
              values.subjectsInfo[s.subject.key].subsubjectsMark[
                sub.subsubject.key
              ] = subMarkData;
              values.subsubjects.push(sub.subsubject.key);
            });
          });
          values.level = this.levelSelected;
          values.trimester = this.selectedTrimester;
          delete values.useSubModule;
          apiClient
            .put("/bulletin/v2/module/", {
              data: values,
            })
            .then((res) => {
              res.data.key = res.data._id;
              this.data.push(res.data);

              this.updateOrders();

              this.$message.success(
                this.$t("success.modificationsMatieresEffectue")
              );

              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoModifiMatieres"))
            )
            .finally(() => {
              this.loadingClasse = false;
              this.targetAddKeys = [];
              this.visibleAddModal = false;
            });
        }
      });
    },
    affSubjects(record) {
      this.selectedModule = record;
      this.targetKeys = record.subjects.map((elem) => elem._id);
      this.selectedSubjects = [];
      if (record.subjectsInfo) {
        for (const [key, value] of Object.entries(record.subjectsInfo)) {
          if (value.sousModule) {
            this.usedSubModule = true;
            break;
          }
        }
      }
      record.subjects.map((elem) => {
        const subsubjects = [];
        if (
          record.subjectsInfo &&
          record.subjectsInfo[elem._id] &&
          record.subjectsInfo[elem._id].subsubjectsMark
        )
          for (const key in record.subjectsInfo[elem._id].subsubjectsMark) {
            const sub = this.listSubsubjects.find((sub) => sub._id == key);
            subsubjects.push({
              subsubject: { key, label: sub.name },
              mark: record.subjectsInfo[elem._id].subsubjectsMark[key].max,
              coef: record.subjectsInfo[elem._id].subsubjectsMark[key].coef,
            });
          }

        this.selectedSubjects.push({
          subject: { key: elem._id, label: elem.name },
          coef: record.subjectsInfo ? record.subjectsInfo[elem._id].coef : 1,
          sousModule: record.subjectsInfo
            ? record.subjectsInfo[elem._id].sousModule
            : undefined,
          subsubjects,
        });
      });

      this.showModal();
    },
    renderItem(item) {
      const customLabel = <span class="custom-item">{item.name}</span>;

      return {
        label: customLabel, // for displayed item
        value: item.name, // for title and filter matching
      };
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    handleAddChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetAddKeys = targetKeys;
    },
    showModal() {
      this.visibleModal = true;
    },
    showAddModal() {
      this.visibleAddModal = true;
      this.selectedSubjects = [];
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      try {
        confirm();
        this.searchText = selectedKeys[0];
        this.searchedColumn = dataIndex;
      } catch (error) {}
    },
    handleCancel(type = "edit") {
      if (type == "edit") {
        this.visibleModal = false;
        this.usedSubModule = false;
        this.form.resetFields();
      } else {
        this.addForm.resetFields();
        this.visibleAddModal = false;
        this.targetAddKeys = [];
      }
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    handleModuleChange(value) {
      this.levelSelected = value;
      this.getModules(value, this.selectedTrimester);
    },

    async updateOrders() {
      const updates = this.data.map((item, index) => ({
        _id: item._id,
        order: index,
      }));

      await apiClient.put("/bulletin/v2/module/update-orders", {
        data: updates,
      });

      this.data = this.data.map((item, index) => ({
        ...item,
        order: index,
      }));
    },
    confirmDelete(module) {
      apiClient
        .delete("/bulletin/v2/module/" + module._id)
        .then(() => {
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== module._id);
          this.updateOrders(module.order);
          this.$message.success(this.$t("success.moduleSupp"));
        })
        .catch(() => this.$message.error(this.$t("error.moduleNonSupp")))
        .finally(() => {
          this.visibleModal = false;
          this.usedSubModule = false;
        });
    },

    supp(data) {
      this.confirmDelete(data);

      // const classroomsUsed = [];

      // apiClient
      //   .post("/schedules/filter", {
      //     query: {
      //       type: "MS",
      //       moduleSpecific: key,
      //       status: { $ne: "inactive" },
      //     },
      //     aggregation: [
      //       {
      //         $lookup: {
      //           from: "classrooms",
      //           localField: "classeRoom",
      //           foreignField: "_id",
      //           as: "classeRoom",
      //         },
      //       },
      //       {
      //         $project: {
      //           _id: 1,
      //           classeRoom: {
      //             $let: {
      //               vars: {
      //                 classVar: {
      //                   $arrayElemAt: ["$classeRoom", 0],
      //                 },
      //               },
      //               in: {
      //                 name: "$$classVar.name",
      //                 _id: "$$classVar._id",
      //               },
      //             },
      //           },
      //         },
      //       },
      //     ],
      //   })
      //   .then(({ data }) => {
      //     data.map((sched) => {
      //       if (sched.classeRoom && sched.classeRoom.name)
      //         classroomsUsed.push(sched.classeRoom.name);
      //     });
      //     if (classroomsUsed.length > 0)
      //       Modal.confirm({
      //         title: this.$t("scolarite.confirmDeleteModule"),
      //         content: (h) => {
      //           let list = [];
      //           classroomsUsed.map((s) => {
      //             list.push(h("li", s));
      //           });
      //           return h("ul", list);
      //         },
      //         okText: this.$t("action.supprimer"),
      //         okType: "danger",
      //         cancelText: this.$t("action.annuler"),
      //         onOk: () => {
      //           this.confirmDelete(key);
      //         },
      //         onCancel() { },
      //       });
      //     else {
      //       this.confirmDelete(key);
      //     }
      //   })
      //   .catch((e) => {
      //     this.$message.error(this.$t("error.moduleNonSupp"));
      //     console.log(e);
      //   })
      //   .finally(() => (this.visibleModal = false));
    },

    closeAddSubsubject() {
      this.addSubsubjectVisible = false;
      this.subsubjectForm.resetFields();
    },

    addSubsubject(e) {
      e.preventDefault();
      this.subsubjectForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/subsubject", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.matiereAjout"));
              this.listSubsubjects.push(res.data);
            })
            .catch((e) => {
              this.$message.error(this.$t("error.matiereNonAjoute"));
              console.error(e);
            })
            .finally(() => {
              this.loadingClasse = false;
              this.closeAddSubsubject();
            });
        }
      });
      this.loadingClasse = false;
    },

    handleChangeSubsubject(value, key, column) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.listSubsubjects = newData;
      }
    },
    editSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      this.subsubjectEditingKey = key;
      if (target) {
        target.editable = true;
        this.listSubsubjects = newData;
      }
    },
    saveSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const newCacheData = [...this.listSubsubjectsCache];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      apiClient
        .patch("/subsubject/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.listSubsubjects = newData;
            Object.assign(targetCache, target);
            this.listSubsubjectsCache = newCacheData;
          }
          this.subsubjectEditingKey = "";
          this.$message.success(this.$t("success.matiereMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.matiereNonMSA"));
          this.cancel(key);
        });
    },
    cancelSubsubject(key) {
      const newData = [...this.listSubsubjects];
      const target = newData.filter((item) => key === item.key)[0];
      this.subsubjectEditingKey = "";
      if (target) {
        Object.assign(
          target,
          this.listSubsubjectsCache.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.listSubsubjects = newData;
      }
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arabicStyle {
  direction: rtl;
  font-size: 13px;
  margin-bottom: 2px;
}

.card >>> .ant-table-tbody tr {
  cursor: pointer !important;
}
.ant-modal-body .card {
  border-color: black !important;
}
.center {
  display: flex;
  align-items: center;
}
</style>

<template>
  <Draggable
    ghostClass="dragging-row"
    tag="tbody"
    :list="data.data"
    @change="onChange"
    :options="{ animation: 200 }"
    handle=".drag-handle"
    :disabled="isUpdating"
  >
    <slot></slot>
  </Draggable>
</template>
<script>
import Draggable from "vuedraggable";
import apiClient from "@/services/axios";

export default {
  components: {
    Draggable,
  },
  inject: ["data"],
  data() {
    return {
      isUpdating: false,
    };
  },
  methods: {
    async onChange(evt) {
      // If no actual change happened, return early
      if (!evt.moved || this.isUpdating) return;

      this.isUpdating = true;

      const updates = this.data.data.map((item, index) => ({
        _id: item._id,
        order: index,
      }));

      try {
        await apiClient.put("/bulletin/v2/module/update-orders", {
          data: updates,
        });
        // Update the orders in local data
        this.data.data = this.data.data.map((item, index) => ({
          ...item,
          order: index,
        }));
        this.$message.success(this.$t("success.moduleOrderUpdated"));
      } catch (error) {
        this.$message.error(this.$t("error.failedToUpdateModuleOrder"));
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>
